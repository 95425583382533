const testimonials_en = [
    {
        name: 'Hanh Do',
        title: 'Business and Crypto',
        twitter: 'https://twitter.com/hanhdo_',
        avatar: `https://pbs.twimg.com/profile_images/1672179172511461376/Ju3D89Rh_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">The course exceeded my expectations. Applying the knowledge from the course helped me achieve my first tweet with 100k views within a week. The instructor's motivational approach greatly contributed to personal development.</p>
                <p>I will enthusiastically introduce KOLs.Network to others and inspire them to recognize the immense potential in the blockchain KOLs profession.</p>
            </>
        )
    },
    {
        name: 'Pham Hung',
        title: 'Office Staff',
        twitter: 'https://twitter.com/orange_arb',
        avatar: `https://pbs.twimg.com/profile_images/1676921247748464640/QKKrraiq_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">The KOLs.Network's training to become Twitter KOLs exceeded my expectations, and I highly appreciate the expertise and dedication of the instructor. I am building my Twitter Account everyday based on the instructions and have achieved some results.</p>
                <p>I am ready to recommend others to join KOLs.Network to become Future KOLs.</p>
            </>
        )
    },
    {
        name: 'Erina',
        title: 'Freelancer',
        twitter: 'https://twitter.com/erinakols',
        avatar: `https://pbs.twimg.com/profile_images/1674435194030559234/b1vhA3x6_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">The valuable knowledge gained from KOLs.Network's training has given me the opportunity to explore new career paths. The instructor's expertise and professionalism have greatly enhanced my understanding and skills. I now feel confident in utilizing Twitter as a platform to share information.  I recommend upcoming multiple short videos to help newbies better understand.</p>
                <p>I am eager to recommend others to join KOLs.Network and become Future KOLs.</p>
            </>
        )
    },
    {
        name: 'Uniart',
        title: 'Designer',
        twitter: 'https://twitter.com/uniartdes',
        avatar: `https://pbs.twimg.com/profile_images/1678638240189005825/Jd-z2GXS_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">The instructor is a highly professional individual with practical experience. I found the training to be comprehensive and suitable for beginners. In a short period of time, the instructor successfully guided us from ground zero to developing a relatively broad understanding of Twitter.</p>
                <p>I am open to suggesting that others enroll in KOLs.Network and strive to become Future KOLs.</p>
            </>
        )
    },
    {
        name: 'Kevin',
        title: 'Office Staff',
        twitter: 'https://twitter.com/Kevin241048',
        avatar: `https://pbs.twimg.com/profile_images/1673163954208772101/Jr13_6ai_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">The training session exceeded my expectations and helped me achieve my learning goals. The instructor's extensive knowledge and passionate approach were truly impressive. I highly recommend this training to anyone seeking a valuable and engaging learning experience. Thank you for providing such an exceptional session.</p>
                <p>I am enthusiastic about promoting KOLs.Network to others and encouraging them to become Future KOLs.</p>
            </>
        )
    },
    {
        name: 'Khai Minh',
        title: 'Business Owner',
        twitter: 'https://twitter.com/khaiminh_csm',
        avatar: `https://pbs.twimg.com/profile_images/1481232791191056384/dpkrqvdN_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">I am highly satisfied with this learning experience and have gained valuable insights. The instructor's level of knowledge and expertise is exceptional. The training has helped me achieve the learning goals I set beforehand. I feel more confident in applying the knowledge and skills I have acquired from the training to build my Twitter KOL career.</p>
                <p>I am willing to vouch for KOLs.Network and suggest others join to become Future KOLs.</p>
            </>
        )
    },
    {
        name: 'Tam Nguyen',
        title: 'Visa Service',
        twitter: 'https://twitter.com/0xcongtam',
        avatar: `https://pbs.twimg.com/profile_images/1660612345977262080/64r7jjhM_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">The training provided detailed step-by-step guidance on building a successful Twitter account. I believe it is the right path to become a prominent KOL on Twitter. The instructor demonstrated a high level of knowledge and expertise. I would like to learn more about effectively managing multiple accounts without account suspension in the next advanced training course.</p>
                <p>I am eager to refer others to KOLs.Network and motivate them to become Future KOLs.</p>
            </>
        )
    },
    {
        name: 'MariaVi',
        title: 'Photographer',
        twitter: 'https://twitter.com/MariaVi_Kols',
        avatar: `https://pbs.twimg.com/profile_images/1672785618089447424/uoWsDUcq_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">The training session exceeded my expectations, and I realize that there is still much more for me to learn in order to accelerate my development. I have achieved my learning goals and feel more confident, especially considering the lack of available guidance elsewhere.</p>
                <p>I am ready to recommend KOLs.Network to others, so they too can have the opportunity to become Future KOLs.</p>
            </>
        )
    },
    {
        name: 'Daniel',
        title: 'Interior Business',
        twitter: 'https://twitter.com/danielkieu1991',
        avatar: `https://pbs.twimg.com/profile_images/1669883793816326144/vBB19Sph_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">I had limited knowledge about Twitter and how to make it more professional and engaging. However, after attending the training and revisiting the provided materials multiple times, I have learned a great deal. The instructor was enthusiastic and dedicated.</p>
                <p>I am more than willing to recommend KOLs.Network to others, providing them with the opportunity to become Future KOLs as well.</p>
            </>
        )
    },
]

const testimonials_vi = [
    {
        name: 'Hanh Do',
        title: 'Kinh doanh và tiền điện tử',
        twitter: 'https://twitter.com/hanhdo_',
        avatar: `https://pbs.twimg.com/profile_images/1672179172511461376/Ju3D89Rh_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">Khóa học vượt xa sự mong đợi của tôi. Áp dụng kiến thức từ khóa học đã giúp tôi đạt được tweet đầu tiên với 100.000 lượt xem trong vòng một tuần. Cách tiếp cận truyền động lực của giảng viên đã đóng góp rất lớn cho sự phát triển cá nhân.</p>
                <p>Tôi sẽ nhiệt tình giới thiệu KOLs.Network cho người khác và truyền cảm hứng cho họ nhận ra tiềm năng không thể đo lường được trong nghề KOLs về blockchain.</p>
            </>
        )
    },
    {
        name: 'Pham Hung',
        title: 'Nhân viên văn phòng',
        twitter: 'https://twitter.com/orange_arb',
        avatar: `https://pbs.twimg.com/profile_images/1676921247748464640/QKKrraiq_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">Chương trình đào tạo của KOLs.Network để trở thành KOL trên Twitter đã vượt xa sự mong đợi của tôi và tôi rất đánh giá cao sự chuyên nghiệp và tận tâm của giảng viên. Tôi đang xây dựng tài khoản Twitter của mình hàng ngày dựa trên những hướng dẫn và đã đạt được một số thành quả đáng kể.</p>
                <p>Tôi hoàn toàn sẵn lòng giới thiệu cho những người khác tham gia KOLs.Network để trở thành Future KOLs.</p>
            </>
        )
    },
    {
        name: 'Erina',
        title: 'Người làm tự do',
        twitter: 'https://twitter.com/erinakols',
        avatar: `https://pbs.twimg.com/profile_images/1674435194030559234/b1vhA3x6_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">Kiến thức quý giá mà tôi đã thu được từ quá trình đào tạo của KOLs.Network đã mở ra cho tôi cơ hội khám phá những con đường nghề nghiệp mới. Chuyên môn và sự chuyên nghiệp của giảng viên đã nâng cao đáng kể sự hiểu biết và kỹ năng của tôi. Bây giờ, tôi tự tin sử dụng Twitter như một nền tảng để chia sẻ thông tin. Tôi đề xuất việc tạo nhiều video ngắn tiếp theo để giúp người mới tham gia hiểu rõ hơn.</p>
                <p>Tôi rất háo hức giới thiệu cho những người khác tham gia KOLs.Network và trở thành Future KOLs.</p>
            </>
        )
    },
    {
        name: 'Uniart',
        title: 'Thiết kế',
        twitter: 'https://twitter.com/uniartdes',
        avatar: `https://pbs.twimg.com/profile_images/1678638240189005825/Jd-z2GXS_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">Giảng viên là một chuyên gia rất chuyên nghiệp với kinh nghiệm thực tế. Tôi thấy quá trình đào tạo rất toàn diện và phù hợp cho người mới bắt đầu. Trong thời gian ngắn, giảng viên đã thành công hướng dẫn chúng tôi từ sơ cấp đến phát triển hiểu biết khá rộng về Twitter.</p>
                <p>Tôi sẵn lòng đề xuất cho người khác đăng ký tham gia KOLs.Network và cố gắng trở thành Future KOLs.</p>
            </>
        )
    },
    {
        name: 'Kevin',
        title: 'Nhân viên văn phòng',
        twitter: 'https://twitter.com/Kevin241048',
        avatar: `https://pbs.twimg.com/profile_images/1673163954208772101/Jr13_6ai_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">Buổi đào tạo vượt xa mong đợi của tôi và giúp tôi đạt được mục tiêu học tập của mình. Kiến thức sâu rộng và cách tiếp cận đầy đam mê của giảng viên thật sự ấn tượng. Tôi rất khuyến khích mọi người tham gia buổi đào tạo này để có một trải nghiệm học tập đáng giá và hấp dẫn. Cảm ơn bạn đã tổ chức một buổi đào tạo đặc biệt như vậy.</p>
                <p>Tôi sẽ nhiệt tình trong việc quảng bá KOLs.Network cho những người khác và khuyến khích họ trở thành Future KOLs.</p>
            </>
        )
    },
    {
        name: 'Khai Minh',
        title: 'Kinh doanh',
        twitter: 'https://twitter.com/khaiminh_csm',
        avatar: `https://pbs.twimg.com/profile_images/1481232791191056384/dpkrqvdN_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">Tôi rất hài lòng với trải nghiệm học tập này và đạt được những thông tin quý giá. Giảng viên có kiến thức và chuyên môn đặc biệt xuất sắc. Khóa đào tạo đã giúp tôi đạt được mục tiêu học tập mà tôi đã đặt ra trước đó. Tôi cảm thấy tự tin hơn trong việc áp dụng kiến thức và kỹ năng mà tôi đã học được từ khóa đào tạo để xây dựng sự nghiệp KOL trên Twitter.</p>
                <p>Tôi rất tin tưởng vào KOLs.Network và muốn đề xuất cho những người khác tham gia để trở thành Future KOLs.</p>
            </>
        )
    },
    {
        name: 'Tam Nguyen',
        title: 'Dịch vụ visa',
        twitter: 'https://twitter.com/0xcongtam',
        avatar: `https://pbs.twimg.com/profile_images/1660612345977262080/64r7jjhM_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">Buổi đào tạo cung cấp hướng dẫn chi tiết từng bước về việc xây dựng một tài khoản Twitter thành công. Tôi tin rằng đây là con đường đúng để trở thành một KOL nổi tiếng trên Twitter. Giảng viên đã thể hiện một mức độ kiến thức và chuyên môn cao. Tôi muốn tìm hiểu thêm về cách quản lý hiệu quả nhiều tài khoản mà không bị đình chỉ tài khoản trong khóa đào tạo nâng cao tiếp theo.</p>
                <p>Tôi rất háo hức để giới thiệu KOLs.Network cho người khác và động viên họ trở thành Future KOLs.</p>
            </>
        )
    },
    {
        name: 'MariaVi',
        title: 'Nhiếp ảnh gia',
        twitter: 'https://twitter.com/MariaVi_Kols',
        avatar: `https://pbs.twimg.com/profile_images/1672785618089447424/uoWsDUcq_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">Buổi đào tạo vượt xa sự mong đợi của tôi và tôi nhận ra rằng còn nhiều điều để tôi học hỏi để thúc đẩy sự phát triển của mình. Tôi đã đạt được mục tiêu học tập của mình và cảm thấy tự tin hơn, đặc biệt khi xét đến sự thiếu hướng dẫn từ các nguồn khác ở bên ngoài.</p>
                <p>Tôi sẵn lòng giới thiệu KOLs.Network cho người khác, để họ cũng có cơ hội trở thành Future KOLs.</p>
            </>
        )
    },
    {
        name: 'Daniel',
        title: 'Kinh doanh nội thất',
        twitter: 'https://twitter.com/danielkieu1991',
        avatar: `https://pbs.twimg.com/profile_images/1669883793816326144/vBB19Sph_400x400.jpg`,
        content: () => (
            <>
                <p className="pb-5">Ban đầu, tôi chỉ có kiến thức hạn chế về Twitter và cách làm cho nó chuyên nghiệp và hấp dẫn hơn. Tuy nhiên, sau khi tham gia buổi đào tạo và xem lại tài liệu được cung cấp nhiều lần, tôi đã học được rất nhiều. Giảng viên rất nhiệt tình và tận tâm.</p>
                <p>Tôi hoan nghênh việc giới thiệu KOLs.Network cho người khác, mang đến cho họ cơ hội trở thành Future KOLs.</p>
            </>
        )
    },
]

export {
    testimonials_en,
    testimonials_vi
}
