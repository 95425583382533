const vi = {
    '$KOLS AIRDROP CAMPAIGN': 'Chiến dịch Airdrop đồng $KOLS',
    '$KOLS GIVEAWAY SPONSORSHIP': 'Chiến dịch tài trợ Giveaway đồng $KOLS',
    'FUTURE KOLS PROGRAM': 'Chương trình trở thành KOLs tương lai',
    'menu': 'Danh sách trang',
    'menu1': 'THÀNH VIÊN',
    'menu2': 'THAM GIA VÀO MẠNG LƯỚI',
    'menu3': 'TÀI LIỆU',
    'menu4': 'TIẾP THỊ LIÊN KẾT',
    'A': 'Một',
    'on Twitter with': 'trên Twitter với',
    'followers could potentially earn up to $': 'người theo dõi có khả năng kiếm được số tiền lên đến ',
    'per year': 'đô la một năm',
    's1-title': 'VÌ SAO LÀ KOLS.NETWORK?',
    's1-content-1': "Nếu chia sẻ ảnh là ưu thế của Instagram thì TikTok lại đi đầu về video ngắn. Với những thông điệp ngắn thì Twitter lại được ưa chuộng hơn. Và khi nói tới mạng lưới đa kênh trên Twitter, KOLs.Network sẽ là cái tên được nhắc đến đầu tiên.",
    's1-content-2': "KOLs.Network tập hợp các nhà sáng tạo nội dung, truyền động lực và đưa ra phương pháp để giúp họ trở thành những KOL chất lượng trên Twitter. Cùng nhau, chúng tôi xây dựng một cộng đồng KOL vững mạnh và sôi động, nơi các thành viên hợp tác, chia sẻ kiến thức và làm việc cùng nhau.",
    's1-button': 'Đọc thêm',
    's2-title': 'VÌ SAO LỰA CHỌN TWITTER?',
    's2-content-1': "Tiềm năng và ảnh hưởng chưa được khai thác của Twitter thu hút những nhà sáng tạo nội dung từ các nền tảng đa dạng, cho phép họ mở rộng tầm ảnh hưởng và tận dụng cơ hội phát triển.",
    's2-content-2': "Với gần 400 triệu người dùng hàng tháng, Twitter đang nổi lên như một trung tâm cho các cuộc trò chuyện về tiền điện tử và blockchain, đồng thời là một nền tảng quan trọng cho các lĩnh vực khác nhau.",
    's2-content-3': "Twitter cung cấp cho những nhà sáng tạo nội dung và KOLs một nền tảng độc đáo và mạnh mẽ để chia sẻ kiến thức chuyên môn, kết nối với khán giả, cập nhật xu hướng ngành và nâng tầm ảnh hưởng trong lĩnh vực của mình.",
    's2-button': 'Tham gia vào đội ngũ Gangs',
    's3-title': 'ĐẶC QUYỀN THÀNH VIÊN KOLS.NETWORK',
    's3-content-1': "Nhận được Airdrops độc quyền và thêm phần thưởng khi số lượng người theo dõi cùng lượt hiển thị tài khoản Twitter của bạn đạt ngưỡng KPI nhất định",
    's3-content-2': "Chương trình đào tạo có cấu trúc cho các KOL tương lai",
    's3-content-3': "Nâng cao danh tiếng và vị thế thông qua sự hỗ trợ của những KOL nổi tiếng",
    's3-content-4': "Cơ hội đầu tư các dự án sớm",
    's3-content-5': "Chương trình chia sẻ lợi nhuận",
    's3-button': 'Tìm hiểu thêm',
    'testimonial-title': 'PHẢN HỒI TỪ HỌC VIÊN',
    'testimonial-description': "Đừng chỉ tin vào lời chúng tôi nói, hãy lắng nghe những trải nghiệm của người khác",
    'kol-title': "THÀNH VIÊN CỦA CHÚNG TÔI",
    'coming-soon': "Đang cập nhật",
    'kol-placeholder': "Nhập tên KOL",
    'copyright': "Bản quyền thuộc về KOLs.Network",
}

export default vi
