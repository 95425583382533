import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { Swiper, SwiperSlide } from 'swiper/react'

import { kols, kolsManager } from 'kols-data'
import { useCallback, useMemo, useState } from 'react'
import { useWindowSize } from 'react-recipes'
import 'swiper/css/effect-fade'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { useTranslation } from 'react-i18next'

const KOLs = () => {
  const [search, setSearch] = useState('')
  const { t } = useTranslation()

  const ks = useCallback(
    (kolList) => {
      return kolList.filter((kol) =>
        kol.name.trim().toLowerCase().includes(search.trim().toLowerCase())
      )
    },
    [search]
  )

  return (
    <section id='kols' className='flex min-h-screen justify-center bg-white'>
      <div className='container relative z-10 flex flex-col items-center gap-10 py-24 md:gap-5'>
        <div className='space-y-1 font-BN font-black text-3xl uppercase md:text-3xl lg:space-y-5 lg:text-5xl text-cpink'>
          <p className='text-center'>{t('kol-title')}</p>
        </div>

        <div className=''>
          <div className='input__container'>
            <div className='shadow__input' />
            <button className='input__button__shadow'>
              <svg
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                height='20px'
                width='20px'
              >
                <path
                  d='M4 9a5 5 0 1110 0A5 5 0 014 9zm5-7a7 7 0 104.2 12.6.999.999 0 00.093.107l3 3a1 1 0 001.414-1.414l-3-3a.999.999 0 00-.107-.093A7 7 0 009 2z'
                  fillRule='evenodd'
                  fill='#17202A'
                />
              </svg>
            </button>
            <input
              type='text'
              name='text'
              className='input__search'
              placeholder={t('kol-placeholder')}
              value={search}
              onChange={({ target: { value } }) => setSearch(value)}
            />
          </div>
        </div>

        <div className='w-full divide-y divide-neutral-800'>
          <KOLSlide title="KOL MANAGERS" kolsList={ks(kolsManager)} />
          <KOLSlide title="KOL GANGS" kolsList={ks(kols)} />
        </div>
      </div>
    </section>
  )
}

export default KOLs

const KOLSlide = ({ title, kolsList }) => {
  const [swiper, setSwiper] = useState()
  const [number, setNumber] = useState(1)

  const { width } = useWindowSize()
  const { t } = useTranslation()

  const slidePerPage = useMemo(() => (width > 1300 ? 6 : 3), [width])

  if (kolsList.length === 0) {
    return (
      <div>
        <div className='text-cpink font-BN font-black text-2xl pt-5'>{title}</div>
        <div className='flex items-center justify-center text-xl min-h-[145px]' style={{ color: '#4C3D59' }}>{t('coming-soon')}</div>
      </div>
    )
  }

  return (
    <div>
      <div className='text-cpink font-BN font-black text-2xl pt-5'>{title}</div>
      <div className='py-5'>
        <div className='flex w-full cursor-pointer items-center justify-end gap-1'>
          <ChevronLeftIcon
            className='w-10 text-black transition-all duration-200 hover:text-red-500'
            onClick={() => swiper.slidePrev()}
          />
          <Swiper
            spaceBetween={50}
            slidesPerView={slidePerPage}
            className='w-full pb-10'
            onInit={(_) => setSwiper(_)}
            onSlideChange={(_) => setNumber(_.activeIndex + 1)}
          >
            {kolsList.map((kol) => (
              <SwiperSlide key={kol.id}>
                <div className='space-y-2 text-black'>
                  <a href={`https://twitter.com/${kol.name}`} target='_blank' rel='noopener'>
                    <div className='w-full overflow-hidden rounded-full'>
                      <img
                        src={kol.avatar}
                        alt='KOL avatar'
                        className='w-full object-contain object-bottom'
                      />
                    </div>
                    <div className='pt-2'>
                      <p className='flex items-center justify-center font-BN font-black text-[14px] md:text-md'>
                        @{kol.name}
                      </p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <ChevronRightIcon
            className='w-10 text-black transition-all duration-200 hover:text-red-500'
            onClick={() => swiper.slideNext()}
          />
        </div>
      </div>
    </div>
  )
}
