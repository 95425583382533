const en = {
  '$KOLS AIRDROP CAMPAIGN': '$KOLS AIRDROP CAMPAIGN',
  '$KOLS GIVEAWAY SPONSORSHIP': '$KOLS GIVEAWAY SPONSORSHIP',
  'FUTURE KOLS PROGRAM': 'FUTURE KOLS PROGRAM',
  'menu': 'MENU',
  'menu1': 'CHECK LEGIT',
  'menu2': 'JOIN NETWORK',
  'menu3': 'DOCUMENT',
  'menu4': 'AFFILIATE',
  'A': 'A',
  'on Twitter with': 'on Twitter with',
  'followers could potentially earn up to $': 'followers could potentially earn up to $',
  'per year': 'per year',
  's1-title': 'WHY KOLs.NETWORK?',
  's1-content-1': "When it comes to photo sharing, Instagram reigns supreme. Short videos? That's the realm of TikTok. For microblogging, Twitter takes the crown. And for a Multi-channel Network on Twitter, KOLs.Network leads the pack.",
  's1-content-2': "KOLs.Network brings together content creators, motivates and provide strategies for them to become high-quality KOLs on Twitter. Together, we build a strong and vibrant community of KOLs who collaborate, share knowledge, and work together.",
  's1-button': 'Read More',
  's2-title': 'WHY KOLs.NETWORK ON TWITTER?',
  's2-content-1': "Twitter's untapped potential for growth and influence attracts Content Creators from diverse platforms, allowing them to expand their influence and seize development opportunities.",
  's2-content-2': "With nearly 400 million monthly users, Twitter emerges a hub for conversations about cryptocurrency and blockchain, making it a significant platform for various industries.",
  's2-content-3': "Twitter provides Content Creators and KOLs a unique and powerful platform to share their expertise, connect with their audience, stay updated on industry trends, and establish their influence in their respective fields.",
  's2-button': 'Join the Gangs',
  's3-title': 'KOLS.NETWORK MEMBERSHIP PRIVILEGES',
  's3-content-1': "Access to exclusive Airdrops and more when your followers and impressions break certain KPI Tier",
  's3-content-2': "Structured training for Future KOLs",
  's3-content-3': "Elevated reputation and status by prominent KOL",
  's3-content-4': "Early-Bird investment opportunities",
  's3-content-5': "Profit-Sharing scheme",
  's3-button': 'Know more',
  'testimonial-title': 'TESTIMONIALS',
  'testimonial-description': "Don't just take our word for it, listen to what others have experienced",
  'kol-title': "OUR MEMBERS",
  'coming-soon': "Coming soon",
  'kol-placeholder': "Enter KOL name",
  'copyright': "Copyright ©2023 by KOLs.Network",
}

export default en
