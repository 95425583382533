// import Banner1 from 'assets/images/social/banner-1.png'
// import Banner2 from 'assets/images/social/banner-2.png'
// import Banner3 from 'assets/images/social/banner-3.png'
// import Banner1vi from 'assets/images/social/banner-1-vi.png'
// import Banner2vi from 'assets/images/social/banner-2-vi.png'
// import Banner3vi from 'assets/images/social/banner-3-vi.png'
import LogoImage from 'assets/images/social/logo.svg'
import Telegram from 'assets/images/social/telegram.svg'
import TwitterIcon from 'assets/images/social/twitter.svg'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-recipes'
import { SOCIAL_URLS } from 'social-url'
import { Autoplay, EffectCube } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useTranslation } from 'react-i18next'
import 'swiper/css/effect-fade'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

const Banner1 = 'https://res.cloudinary.com/doeapodcw/image/upload/f_auto/q_auto/v1689325550/banner-1.png'
const Banner1vi = 'https://res.cloudinary.com/doeapodcw/image/upload/f_auto/q_auto/v1689324946/banner-1-vi.png'
const Banner2 = 'https://res.cloudinary.com/doeapodcw/image/upload/f_auto/q_auto/v1689325550/banner-2.png'
const Banner2vi = 'https://res.cloudinary.com/doeapodcw/image/upload/f_auto/q_auto/v1689325550/banner-2-vi.png'
const Banner3 = 'https://res.cloudinary.com/doeapodcw/image/upload/f_auto/q_auto/v1689325550/banner-3.png'
const Banner3vi = 'https://res.cloudinary.com/doeapodcw/image/upload/f_auto/q_auto/v1689325550/banner-3-vi.png'

const HeroSection = () => {
  const [menu, setMenu] = useState({ desktop: false, mobile: false })
  const [swiper, setSwiper] = useState(null)
  const [slideIndex, setSlideIndex] = useState(0)
  const [selectLanguage, setSelectLanguage] = useState(false)

  const { i18n, t } = useTranslation()

  const { width } = useWindowSize()

  const handleMenu = () => {
    if (width < 768) {
      setMenu((prev) => ({
        ...prev,
        mobile: !prev.mobile
      }))
    } else {
      setMenu((prev) => ({
        ...prev,
        desktop: !prev.desktop
      }))
    }
  }

  const getFlag = (lang) => {
    if (lang === 'en') return 'https://flagcdn.com/24x18/us.png'
    return 'https://flagcdn.com/24x18/vn.png'
  }

  useEffect(() => {
    if (width < 768)
      setMenu((prev) => ({
        ...prev,
        desktop: false
      }))
    else setMenu((prev) => ({ ...prev, mobile: false }))
  }, [width])

  return (
    <section className='hero-section relative z-[1] flex h-[60vh] flex-col items-center justify-between md:h-screen'>
      <header className='container relative z-50 flex justify-between font-BN font-black text-white'>
        <div className='z-10 flex items-end justify-between pt-3 uppercase'>
          <div className='w-[100px] md:w-[120px]'>
            <img src={LogoImage} alt='logo' className='w-full' />
          </div>
        </div>
        <div className='z-50 flex gap-4'>
          <div className='flex items-center relative'>
            <img
              className='cursor-pointer'
              src={getFlag(i18n.language)}
              alt={i18n.language}
              onClick={() => setSelectLanguage(true)}
            />
            {selectLanguage && (
              <div className='absolute top-[3rem] md:top-[3.5rem]'>
                {i18n.language === 'en' ? (
                  <img
                    className='cursor-pointer'
                    src={getFlag('vi')}
                    alt='vi'
                    onClick={() => {
                      i18n.changeLanguage('vi')
                      setSelectLanguage(false)
                    }}
                  />
                ) : (
                  <img
                    className='cursor-pointer'
                    src={getFlag('en')}
                    alt='en'
                    onClick={() => {
                      i18n.changeLanguage('en')
                      setSelectLanguage(false)
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div
            className='z-50 flex cursor-pointer select-none items-center gap-4 text-xl uppercase text-white'
            onClick={handleMenu}
          >
            {/* <p className=''>{t('menu')}</p> */}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='h-6 w-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z'
              />
            </svg>
          </div>
        </div>
        <div
          className={`absolute left-0 top-full z-[51] w-full rounded-md 
          bg-white shadow-xl transition-all duration-200 
          md:hidden
          ${menu.mobile ? 'visible opacity-100' : 'invisible opacity-0'}
          `}
        >
          <div className='relative z-[51] space-y-1 px-2 pb-3 pt-2'>
            <a
              href={'#kols'}
              className='block rounded-md px-3 py-2 text-base font-medium text-neutral-900 hover:bg-gray-700 hover:text-white'
              aria-current='page'
            >
              {t('menu1')}
            </a>
            <a
              href={SOCIAL_URLS.JOIN_NETWORK}
              className='block rounded-md px-3 py-2 text-base font-medium text-neutral-900 hover:bg-gray-700 hover:text-white'
              target='blank'
              onClick={handleMenu}
            >
              {t('menu2')}
            </a>
            <a
              href={SOCIAL_URLS.DOCUMENT}
              className='block rounded-md px-3 py-2 text-base font-medium text-neutral-900 hover:bg-gray-700 hover:text-white'
              target='blank'
              onClick={handleMenu}
            >
              {t('menu3')}
            </a>
            <a
              href={SOCIAL_URLS.AFFILIATE}
              className='block rounded-md px-3 py-2 text-base font-medium text-neutral-900 hover:bg-gray-700 hover:text-white'
              target='blank'
              onClick={handleMenu}
            >
              {t('menu4')}
            </a>
          </div>
        </div>
      </header>
      <div
        className={`container absolute top-[10vh] z-50 hidden items-center justify-end 
        px-1 pb-32 text-white md:flex md:pb-[1rem]
        ${menu.desktop ? 'visible opacity-100' : 'invisible opacity-0'}`}
      >
        <ul
          className={`hidden space-y-3 text-right font-BN font-black text-md font-medium
          uppercase tracking-[0.2em] transition-all duration-300
          md:block
          ${menu.desktop ? 'visible opacity-100' : 'invisible opacity-0'}
          `}
        >
          <li className='hover:text-red-500'>
            <a href={'#kols'}>{t('menu1')}</a>
          </li>
          <li className='hover:text-red-500'>
            <a
              href={SOCIAL_URLS.JOIN_NETWORK}
              target='blank'
              rel='noreferrer'
              onClick={handleMenu}
            >
              {t('menu2')}
            </a>
          </li>
          <li className='hover:text-red-500'>
            <a
              href={SOCIAL_URLS.DOCUMENT}
              target='blank'
              rel='noreferrer'
              onClick={handleMenu}
            >
              {t('menu3')}
            </a>
          </li>
          <li className='hover:text-red-500'>
            <a
              href={SOCIAL_URLS.AFFILIATE}
              target='blank'
              rel='noreferrer'
              onClick={handleMenu}
            >
              {t('menu4')}
            </a>
          </li>
        </ul>
      </div>
      <div className='container relative z-10 flex h-32 items-center bg-none md:h-20 md:bg-white'>
        <ul className='flex w-full flex-col justify-around p-4 font-BN text-md font-medium uppercase text-white md:flex-row md:items-center md:text-xl md:text-[#6D6874]'>
          {[
            i18n.language === 'en' ? "$KOLS Airdrop Campaign" : "Chiến dịch Airdrop đồng $KOLS",
            i18n.language === 'en' ? "$KOLS Giveaway Sponsorship" : "Chiến dịch tài trợ Giveaway đồng $KOLS",
            i18n.language === 'en' ? "Future KOLs Program" : "Chương trình trở thành KOLs tương lai",
          ].map((_, idx) => (
            <SlideController
              key={idx}
              number={idx}
              swiper={swiper}
              currentIndex={slideIndex}
            >
              {_}
            </SlideController>
          ))}
        </ul>
      </div>
      <div className='absolute bottom-0 right-0 z-[1] hidden h-20 w-1/2 bg-white md:block' />
      <div
        className='container absolute bottom-[8rem] z-10 h-[calc(100%-8rem)] w-full
        md:bottom-[20vh] md:h-[calc(100%-20rem)] 
        lg:bottom-[5rem] lg:h-[calc(100%-5rem)]'
      >
        <Swiper
          className='h-full'
          modules={[EffectCube, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 5000
          }}
          onSwiper={setSwiper}
          onSlideChange={({ activeIndex }) => setSlideIndex(activeIndex)}
        >
          <SwiperSlide>
            <a
              className='flex h-full items-end'
              href={SOCIAL_URLS.SLIDE_1}
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={i18n.language === 'en' ? Banner1 : Banner1vi}
                alt='$KOLS Airdrop Campaign'
                className='h-full w-full object-contain object-bottom'
              />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              className='flex h-full items-end'
              href={SOCIAL_URLS.SLIDE_2}
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={i18n.language === 'en' ? Banner2 : Banner2vi}
                alt='$KOLS Giveaway Sponsorship'
                className='h-full w-full object-contain object-bottom'
              />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              className='flex h-full w-full items-end'
              href={SOCIAL_URLS.SLIDE_3}
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={i18n.language === 'en' ? Banner3 : Banner3vi}
                alt='Future KOLs Program'
                className='h-full w-full object-contain object-bottom'
              />
            </a>
          </SwiperSlide>
        </Swiper>
        <div className='absolute bottom-0 right-2.5 z-10 flex items-center gap-3 text-white md:bottom-5 md:right-0 md:gap-7'>
          <div className='cursor-pointer'>
            <a href={SOCIAL_URLS.TWITTER} target='_blank' rel='noreferrer'>
              <img src={TwitterIcon} alt='twitter icon' title='Twitter' />
            </a>
          </div>
          <div className='cursor-pointer'>
            <a href={SOCIAL_URLS.TELEGRAM} target='_blank' rel='noreferrer'>
              <img src={Telegram} alt='telegram icon' title='Telegram' />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection

const SlideController = ({ number, currentIndex, swiper, children }) => {
  return (
    <li
      className={`cursor-pointer text-center transition-all duration-200 ease-linear
      ${currentIndex === number ? 'text-cpink' : ''}`}
      onClick={() => {
        if (swiper) swiper.slideTo(number)
      }}
    >
      {children}
    </li>
  )
}
