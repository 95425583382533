const SOCIAL_URLS = {
  TWITTER: 'https://twitter.com/KolsNetwork',
  TELEGRAM: 'https://t.me/KOLSNetwork',
  SLIDE_1: 'https://info.kols.network/membership/membership-recruitment',
  SLIDE_2: 'https://twitter.com/KolsNetwork/status/1675756498532839425?s=20',
  SLIDE_3: 'https://twitter.com/KolsNetwork',
  JOIN_NETWORK: 'https://info.kols.network/membership/membership-recruitment',
  DOCUMENT: 'https://info.kols.network/overview/who-we-are',
  AFFILIATE: 'https://info.kols.network/info/affiliate-program',
  MORE: {
    SECTION_1: 'https://info.kols.network/overview/who-we-are',
    SECTION_2:
      'https://info.kols.network/membership/member-recruitment',
    SECTION_3: 'https://info.kols.network/membership/membership-privileges'
  }
}

export { SOCIAL_URLS }
