import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { Swiper, SwiperSlide } from 'swiper/react'
import star from 'assets/images/social/star.png'
import quote from 'assets/images/social/quote.svg'
import { testimonials_en, testimonials_vi } from 'testimonial-data'
import { useCallback, useMemo, useState } from 'react'
import { useWindowSize } from 'react-recipes'
import 'swiper/css/effect-fade'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Autoplay, EffectCards } from 'swiper'
import { useTranslation } from 'react-i18next'

const Testimonials = () => {
  const { i18n, t } = useTranslation()

  return (
    <section className='flex justify-center bg-[#fffccc]'>
      <div className='container relative z-10 flex flex-col items-center gap-10 py-24 md:gap-5'>
        <div className='space-y-1 font-BN font-black text-3xl uppercase md:text-3xl lg:space-y-5 lg:text-5xl'
          style={{ color: '#4C3D59' }}
        >
          <p className='text-center'>{t('testimonial-title')}</p>
        </div>
        <div className='text-xl md:text-2xl lg:space-y-5 lg:text-2xl'
          style={{ color: '#4C3D59' }}
        >
          <p className='text-center'>{t('testimonial-description')}</p>
        </div>
        <div className='w-full divide-y divide-neutral-800 mt-10'>
          <TestimonialSilde title="KOL MANAGERS" itemList={i18n.language === 'en' ? testimonials_en : testimonials_vi} />
        </div>
      </div>
    </section>
  )
}

export default Testimonials

const TestimonialSilde = ({ title, itemList }) => {
  const [swiper, setSwiper] = useState()
  const [number, setNumber] = useState(1)

  const { width } = useWindowSize()

  const slidePerPage = useMemo(() => (width > 996 ? 3 : 1), [width])

  return (
    <div>
      <div className='py-5'>
        <div className='flex w-full cursor-pointer items-center justify-end gap-3'>
          <Swiper
            spaceBetween={50}
            slidesPerView={slidePerPage}
            modules={[EffectCards, Autoplay]}
            className='w-full pb-10'
            onInit={(_) => setSwiper(_)}
            onSlideChange={(_) => setNumber(_.activeIndex + 1)}
            autoplay={{
              delay: 5000
            }}
          >
            {itemList.map((item) => (
              <SwiperSlide key={item.id}>
                <div className='space-y-2 text-black min-h-[300px]'>
                  <div className='flex justify-between items-center'>
                    <div><img className='w-[38px] rounded-full' src={quote} /></div>
                    <div className='flex space-x-1'>
                      {[...Array(5)].map((i) => (
                        <img className='w-[18px]' src={star} key={i} />
                      ))}
                    </div>
                  </div>
                  <div className='bg-white p-5 rounded-[20px]'>
                    {item.content()}
                  </div>
                  <div className='flex space-x-3 p-3'>
                    <div className='overflow-hidden rounded-full'>
                      <img
                        src={item.avatar}
                        alt='KOL people'
                        className='w-[50px] object-contain object-bottom'
                      />
                    </div>
                    <div className=''>
                      <p className='flex items-center justify-start font-AB'>
                        <a href={item.twitter} target='_blank' rel='noopener'>{item.name}</a>
                      </p>
                      <p className='flex items-center justify-start text-sm'>
                        {item.title}
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}
