import BulletIcon from 'assets/images/logo32.png'
// import Section2Image from 'assets/images/social/section-2.png'
// import Section3Image from 'assets/images/social/section-3.png'
// import Section4Image from 'assets/images/social/section-4.png'
import { HeroSection, KOLs } from 'components/LandingPage'
import Testimonials from 'components/LandingPage/Testimonials'
import { useTranslation } from 'react-i18next'
import { SOCIAL_URLS } from 'social-url'

const Section2Image = 'https://res.cloudinary.com/doeapodcw/image/upload/f_auto/q_auto/v1689325591/section-2.png'
const Section3Image = 'https://res.cloudinary.com/doeapodcw/image/upload/f_auto/q_auto/v1689325584/section-3.png'
const Section4Image = 'https://res.cloudinary.com/doeapodcw/image/upload/f_auto/q_auto/v1689325584/section-4.png'

const LandingPage = () => {
  const { t } = useTranslation()

  return (
    <div>
      <>
        {/* SECTION 1 */}
        <HeroSection />

        {/* SECTION 2 */}
        <section className='z-20 min-h-screen !px-0' id='about'>
          <div
            className='text-md container m-auto px-5 py-10 text-center md:px-0 md:py-20 lg:text-xl'
            style={{ color: '#4C3D59' }}
          >
            "{t("A")} <span className='text-cpink font-bold'>Blockchain KOL</span> {t("on Twitter with")} <span className='font-bold'>10,000</span> {t("followers could potentially earn up to $")}<span className='font-bold'>100,000</span> {t("per year")}."
          </div>
          <div className='relative flex items-center justify-center pb-10 md:pb-20'>
            <div className='container flex flex-col items-center justify-between gap-10 md:flex-row'>
              <div className='z-10 flex w-full flex-col justify-center px-5 py-10 sm:px-6 md:w-[55%] md:px-0 md:py-0'>
                <div
                  className='mb-10 space-y-1 font-BN font-black text-3xl uppercase text-neutral-800 md:text-3xl lg:space-y-5 lg:text-5xl'
                  style={{ color: '#4C3D59' }}
                >
                  <p className='!leading-tight'>{t('s1-title')}</p>
                </div>
                <ul
                  className='text-bold list-inside text-xl md:text-justify'
                  style={{ color: '#6F6977' }}
                >
                  <li className='mt-4'>
                    {t('s1-content-1')}
                  </li>
                  <li className='mb-14 mt-4'>
                    {t('s1-content-2')}
                  </li>
                </ul>
                <a
                  href={SOCIAL_URLS.MORE.SECTION_1}
                  target='_blank'
                  rel='noreferrer'
                >
                  <div
                    className='relative bottom-1 w-max rounded-full px-5 py-2 text-2xl font-bold shadow-xl shadow-cpink/60'
                    style={{
                      backgroundImage:
                        'linear-gradient(to right, #F5B81C , #FFFAA9)',
                      border: '2px solid #DD004F',
                      color: '#DD004F'
                    }}
                  >
                    {t('s1-button')}
                  </div>
                </a>
              </div>
              {/* <div className='z-1 absolute bottom-0 blur-sm md:relative md:block md:blur-0 flex w-full justify-end md:w-[42%]'> */}
              <div className='flex w-full justify-end md:w-[42%]'>
                <img
                  src={Section2Image}
                  alt='person'
                  className='h-full w-full object-contain'
                />
              </div>
            </div>
          </div>
        </section>
        {/* SECTION 3 */}
        <section
          className='relative flex items-end bg-cblue !px-0 2xl:justify-center'
          id='works'
        >
          <div className='z-1 absolute bottom-0 blur-sm md:relative md:block md:blur-0'>
            <img src={Section3Image} alt='section 3' />
          </div>
          <div className='relative z-10 space-y-10 px-5 py-[7rem] sm:py-10 sm:px-6 md:py-[7rem] md:px-[7rem]'>
            <div className='space-y-1 font-BN font-black text-3xl uppercase text-white md:text-3xl lg:space-y-5 lg:text-5xl'>
              <p className='!leading-tight'>{t('s2-title')}</p>
            </div>
            <ul className='text-bold text-xl text-white/90 md:text-justify lg:max-w-[450px]'>
              <li className='mt-4'>
                {t('s2-content-1')}
              </li>
              <li className='mt-4'>
                {t('s2-content-2')}
              </li>
              <li className='mt-4'>
                {t('s2-content-3')}
              </li>
            </ul>
            <a
              href={SOCIAL_URLS.MORE.SECTION_2}
              target='_blank'
              rel='noreferrer'
            >
              <div className='mt-10 flex w-max items-center gap-3 rounded-full bg-white px-5 py-2 text-2xl font-bold text-cblue shadow-xl'>
                {t('s2-button')}
              </div>
            </a>
          </div>
        </section>
        {/* SECTION 4 */}
        <section
          id='services'
          className='flex min-h-screen justify-center bg-gradient-to-r from-[#1e0131] to-[#01021b]'
        >
          <div className='container relative z-10 flex flex-col items-center justify-between gap-10 py-24 md:gap-5 lg:flex-row'>
            <div className='z-10 !pb-20 sm:!pb-32 md:!pb-0'>
              <div className='space-y-1 font-BN text-3xl uppercase text-white md:text-3xl lg:space-y-5 lg:text-5xl lg:max-w-[600px]'>
                <p className='!leading-tight'>{t('s3-title')}</p>
              </div>
              <ul className='text-bold mb-14 mt-10 text-xl text-white/90 md:text-justify lg:max-w-[500px]'>
                <li className='mt-3 flex'>
                  <img src={BulletIcon} className='relative top-[5px]' style={{ width: 18, height: 18, float: 'left'}} alt=''/>
                  <p>{t('s3-content-1')}</p>
                </li>
                <li className='mt-3 flex'>
                  <img
                    src={BulletIcon}
                    className='relative top-[5px]'
                    style={{ width: 18, height: 18, float: 'left' }}
                    alt=''
                  />
                  <p>{t('s3-content-2')}</p>
                </li>
                <li className='mt-3 flex'>
                  <img src={BulletIcon} className='relative top-[5px]' style={{ width: 18, height: 18, float: 'left'}} alt=''/>
                  <p>{t('s3-content-3')}</p>
                </li>
                <li className='mt-3 flex'>
                  <img
                    src={BulletIcon}
                    className='relative top-[5px]'
                    style={{ width: 18, height: 18, float: 'left' }}
                    alt=''
                  />
                  <p>{t('s3-content-4')}</p>
                </li>
                <li className='mt-3 flex'>
                  <img
                    src={BulletIcon}
                    className='relative top-[5px]'
                    style={{ width: 18, height: 18, float: 'left' }}
                    alt=''
                  />
                  <p>{t('s3-content-5')}</p>
                </li>
              </ul>
              <a
                href={SOCIAL_URLS.MORE.SECTION_3}
                target='_blank'
                rel='noreferrer'
              >
                <div
                  className='relative bottom-1 w-max rounded-full px-5 py-2 text-2xl font-bold shadow-xl shadow-cpink/60'
                  style={{
                    backgroundImage:
                      'linear-gradient(to right, #F5B81C , #FFFAA9)',
                    border: '2px solid #DD004F',
                    color: '#DD004F'
                  }}
                >
                  {t('s3-button')}
                </div>
              </a>
            </div>
            <div className='absolute bottom-0 right-0 z-[1] flex items-end blur-sm md:blur-0'>
              <img src={Section4Image} alt='section 4' className='w-[429px]' />
            </div>
          </div>
        </section>

        <Testimonials />
        <KOLs />

        <footer
          className='relative z-10 flex items-center justify-center gap-40 bg-cpink py-5'
          id='contact'
        >
          {/* <div className='absolute z-[1] h-full blur-sm md:static md:h-auto md:blur-0'>
            <img
              src={Section5Image}
              alt='section 5'
              className='h-full object-contain md:h-auto md:object-fill'
            />
          </div> */}
          <div className='container z-10 w-full p-4 text-center text-xs tracking-[0.2em] text-white md:gap-6 md:p-0 md:text-md'>
            <p>{t('copyright')}</p>
          </div>
        </footer>
      </>
    </div>
  )
}

export default LandingPage
