// const kols = [
//     {
//         id: '34i2ur5hnk34jr5435',
//         name: 'xm92i',
//         title: 'Founder & Designer',
//         avatar: `https://api.multiavatar.com/xm92boi.svg`,
//         country: 'https://flagcdn.com/48x36/bd.png',
//     },
//     {
//         id: '34i2ur5hnk34jr5435',
//         name: 'APP0DL',
//         title: 'Marketing',
//         avatar: `https://api.multiavatar.com/APP0D14L.svg`,
//         country: 'https://flagcdn.com/48x36/af.png',
//     },
//     {
//         id: '34i2ur5hnk34jr5435',
//         name: 'itbuzz',
//         title: 'Networking',
//         avatar: `https://api.multiavatar.com/itburnzz.svg`,
//         country: 'https://flagcdn.com/48x36/au.png',
//     },
//     {
//         id: '34i2ur5hnk34jr5435',
//         name: '2F_zer',
//         title: 'Marketing & Advocee',
//         avatar: `https://api.multiavatar.com/B2F_zer.svg`,
//         country: 'https://flagcdn.com/48x36/gb.png',
//     },
//     {
//         id: '34i2ur5hnk34jr5435',
//         name: 'defipl',
//         title: 'Labs Shakespeare',
//         avatar: `https://api.multiavatar.com/defipleb.svg`,
//         country: 'https://flagcdn.com/48x36/us.png',
//     },
//     {
//         id: '34i2ur5hnk34jr5435',
//         name: 'kingbin',
//         title: 'Motion Designer',
//         avatar: `https://api.multiavatar.com/kingblockchain.svg`,
//         country: 'https://flagcdn.com/48x36/za.png',
//     },
//     {
//         id: '34i2ur5hnk34jr5435',
//         name: 'xm9boi',
//         title: 'Discord Mod',
//         avatar: `https://api.multiavatar.com/xm92boi.svg`,
//         country: 'https://flagcdn.com/48x36/fr.png',
//     },
// ]

const kols = []

const kolsManager = [
    {
        "id": 1,
        "name": "0x99Gohan",
        "avatar": "https://pbs.twimg.com/profile_images/1681778919693860864/QObMp6RF_400x400.png"
    },
    {
        "id": 2,
        "name": "cryptoshinn",
        "avatar": "https://pbs.twimg.com/profile_images/1624718617375019008/wh7IIRd__400x400.jpg"
    },
    {
        "id": 3,
        "name": "0xAndrewmoh",
        "avatar": "https://pbs.twimg.com/profile_images/1688204016843735041/9BsdHQCi_400x400.jpg"
    },
    {
        "id": 4,
        "name": "wendyxorchid",
        "avatar": "https://pbs.twimg.com/profile_images/1679150391181848576/gep_Kopx_400x400.jpg"
    },
    {
        "id": 5,
        "name": "uniartdes",
        "avatar": "https://pbs.twimg.com/profile_images/1678638240189005825/Jd-z2GXS_400x400.jpg"
    },
    {
        "id": 6,
        "name": "nekokols9",
        "avatar": "https://pbs.twimg.com/profile_images/1688773122361901057/fOI2cehA_400x400.jpg"
    },
    {
        "id": 7,
        "name": "Caily_kols",
        "avatar": "https://pbs.twimg.com/profile_images/1672604840957181959/7-dBckP3_400x400.jpg"
    },
    {
        "id": 8,
        "name": "cryptokidreal",
        "avatar": "https://pbs.twimg.com/profile_images/1671450793365618688/QzmvNunN_400x400.jpg"
    },
    {
        "id": 9,
        "name": "hanhdo_",
        "avatar": "https://pbs.twimg.com/profile_images/1672179172511461376/Ju3D89Rh_400x400.jpg"
    },
    {
        "id": 10,
        "name": "0xMavelWeb3",
        "avatar": "https://pbs.twimg.com/profile_images/1531893128961327104/Xw9N7Png_400x400.jpg"
    },
    {
        "id": 11,
        "name": "FancyGemsETH",
        "avatar": "https://pbs.twimg.com/profile_images/1605377249342939136/ZmSlF3SI_400x400.jpg"
    },
    {
        "id": 12,
        "name": "Hai12xVentures",
        "avatar": "https://pbs.twimg.com/profile_images/1584806603722608640/0UULZ7X1_400x400.jpg"
    },
    {
        "id": 13,
        "name": "hungsatoshi99",
        "avatar": "https://pbs.twimg.com/profile_images/1598612121012424704/u_VpyICq_400x400.jpg"
    },
    {
        "id": 14,
        "name": "JulyCrypto",
        "avatar": "https://pbs.twimg.com/profile_images/1588883920506216450/7mwPXN0J_400x400.jpg"
    },
    {
        "id": 15,
        "name": "bellakols_",
        "avatar": "https://pbs.twimg.com/profile_images/1673186814457413634/HzHKG4a6_400x400.jpg"
    },
    {
        "id": 16,
        "name": "Alice_babie94",
        "avatar": "https://pbs.twimg.com/profile_images/1672881108818751490/bSiWNRWP_400x400.jpg"
    },
    {
        "id": 17,
        "name": "VeeKeyCrypto",
        "avatar": "https://pbs.twimg.com/profile_images/1594602065732304896/I6wTR-r-_400x400.jpg"
    },
    {
        "id": 18,
        "name": "IvankaKols",
        "avatar": "https://pbs.twimg.com/profile_images/1660178053509844993/8CJDN-Dk_400x400.jpg"
    },
    {
        "id": 19,
        "name": "GraceTrebb",
        "avatar": "https://pbs.twimg.com/profile_images/1672982019104718848/t6wbPygB_400x400.jpg"
    },
    {
        "id": 20,
        "name": "kentlee5353",
        "avatar": "https://pbs.twimg.com/profile_images/1588886460635447296/W8mqBNVm_400x400.jpg"
    },
    {
        "id": 21,
        "name": "roseanneconner9",
        "avatar": "https://pbs.twimg.com/profile_images/1681154713855856640/LxwEB0a1_400x400.jpg"
    },
    {
        "id": 22,
        "name": "199xtrading",
        "avatar": "https://pbs.twimg.com/profile_images/1669729272565755904/a4kzqB26_400x400.jpg"
    },
    {
        "id": 23,
        "name": "kevilcrypto",
        "avatar": "https://pbs.twimg.com/profile_images/1682952253572018181/i5TJHeGK_400x400.jpg"
    },
    {
        "id": 24,
        "name": "Amyykols",
        "avatar": "https://pbs.twimg.com/profile_images/1683315657163808771/S-Am3l6e_400x400.jpg"
    },
    {
        "id": 25,
        "name": "KentDefi",
        "avatar": "https://pbs.twimg.com/profile_images/1682802729268424704/BCztxnFA_400x400.jpg"
    },
    {
        "id": 26,
        "name": "LukeBitPro",
        "avatar": "https://pbs.twimg.com/profile_images/1683004918867820544/-8Q-JZub_400x400.jpg"
    },
    {
        "id": 27,
        "name": "BigManCrypto12x",
        "avatar": "https://pbs.twimg.com/profile_images/1619945665211277312/0gnYd0n-_400x400.jpg"
    },
    {
        "id": 29,
        "name": "bella_3211n",
        "avatar": "https://pbs.twimg.com/profile_images/1683697807461588992/nG0Uopid_400x400.jpg"
    },
    {
        "id": 30,
        "name": "alice_global94",
        "avatar": "https://pbs.twimg.com/profile_images/1679879138575028224/kh9imoE2_400x400.jpg"
    },
    {
        "id": 31,
        "name": "ot5_larry",
        "avatar": "https://pbs.twimg.com/profile_images/1685122215698989056/TcwFbZnw_400x400.jpg"
    },
    {
        "id": 32,
        "name": "JoeMLynk",
        "avatar": "https://pbs.twimg.com/profile_images/1397700957803204609/Em9sPGFg_400x400.jpg"
    },
    {
        "id": 33,
        "name": "MarcoNguyenDeFi",
        "avatar": "https://pbs.twimg.com/profile_images/1682291127163195392/Fvw8vgFk_400x400.jpg"
    },
    {
        "id": 34,
        "name": "CryptosTomzZ",
        "avatar": "https://pbs.twimg.com/profile_images/1690445219186278400/Qnv1xY5i_400x400.png"
    },
    {
        "id": 35,
        "name": "nbliza_369",
        "avatar": "https://pbs.twimg.com/profile_images/1684826493166432256/mf2rYnAy_400x400.jpg"
    },
    {
        "id": 36,
        "name": "SomonePr",
        "avatar": "https://pbs.twimg.com/profile_images/1605558115121782786/tDpNwp96_400x400.jpg"
    },
    {
        "id": 37,
        "name": "EmilyKols69",
        "avatar": "https://pbs.twimg.com/profile_images/1679345914530631683/VH1VF7xt_400x400.jpg"
    },
    {
        "id": 38,
        "name": "tiendong8888",
        "avatar": "https://pbs.twimg.com/profile_images/1670813114991009792/fglqCgD-_400x400.jpg"
    },
    {
        "id": 39,
        "name": "namka12x",
        "avatar": "https://pbs.twimg.com/profile_images/1623761137325191169/o9K2i2i9_400x400.jpg"
    },
    {
        "id": 40,
        "name": "Cris_csm23",
        "avatar": "https://pbs.twimg.com/profile_images/1669847970601373696/9qyTG4x-_400x400.jpg"
    },
    {
        "id": 42,
        "name": "capu_puu8",
        "avatar": "https://pbs.twimg.com/profile_images/1684775941858557953/Bjox-Wgx_400x400.jpg"
    },
    {
        "id": 43,
        "name": "hebe_kols",
        "avatar": "https://pbs.twimg.com/profile_images/1673612512892256257/j_hXDpUX_400x400.jpg"
    },
    {
        "id": 44,
        "name": "erinakols",
        "avatar": "https://pbs.twimg.com/profile_images/1674435194030559234/b1vhA3x6_400x400.jpg"
    },
    {
        "id": 45,
        "name": "danielkieu1991",
        "avatar": "https://pbs.twimg.com/profile_images/1669883793816326144/vBB19Sph_400x400.jpg"
    },
    {
        "id": 46,
        "name": "Thiepruby6868",
        "avatar": "https://pbs.twimg.com/profile_images/1690738725343604738/BxAYx-os_400x400.jpg"
    },
    {
        "id": 47,
        "name": "Sunto_Csm",
        "avatar": "https://pbs.twimg.com/profile_images/1670033576409337858/Io71AygZ_400x400.jpg"
    },
    {
        "id": 48,
        "name": "thongstockpizon",
        "avatar": "https://pbs.twimg.com/profile_images/1678734678851977217/LXsF1I4T_400x400.jpg"
    },
    {
        "id": 49,
        "name": "hanztrinh",
        "avatar": "https://pbs.twimg.com/profile_images/1682012864733933570/cG6D-z5Q_400x400.png"
    },
    {
        "id": 50,
        "name": "KOL_CSM",
        "avatar": "https://pbs.twimg.com/profile_images/1674332516701077506/l5vkBVqk_400x400.jpg"
    },
    {
        "id": 51,
        "name": "CryptoTyff",
        "avatar": "https://pbs.twimg.com/profile_images/1674723013437313024/oCwBmhAh_400x400.jpg"
    },
    {
        "id": 52,
        "name": "CelolabsTech",
        "avatar": "https://pbs.twimg.com/profile_images/1685297395293433856/RkFlSyz0_400x400.jpg"
    },
    {
        "id": 53,
        "name": "0xTiMan",
        "avatar": "https://pbs.twimg.com/profile_images/1657963564706197506/aGW9-0hy_400x400.jpg"
    },
    {
        "id": 54,
        "name": "mein_csm",
        "avatar": "https://pbs.twimg.com/profile_images/1671337273986797568/of__RzJV_400x400.jpg"
    },
    {
        "id": 55,
        "name": "MariaVi_Kols",
        "avatar": "https://pbs.twimg.com/profile_images/1672785618089447424/uoWsDUcq_400x400.jpg"
    },
    {
        "id": 56,
        "name": "0xcongtam",
        "avatar": "https://pbs.twimg.com/profile_images/1660612345977262080/64r7jjhM_400x400.jpg"
    },
    {
        "id": 57,
        "name": "countryman_csm",
        "avatar": "https://pbs.twimg.com/profile_images/1674688800910639104/jBBrtA6o_400x400.jpg"
    },
    {
        "id": 58,
        "name": "changeengin",
        "avatar": "https://pbs.twimg.com/profile_images/1683713881951338496/WEsWfI_b_400x400.png"
    },
    {
        "id": 59,
        "name": "cuongtroc12x",
        "avatar": "https://pbs.twimg.com/profile_images/1690443725120720896/QDmZ166e_400x400.png"
    },
    {
        "id": 60,
        "name": "0x_daVincii",
        "avatar": "https://pbs.twimg.com/profile_images/1676942778415345665/A5PR21WQ_400x400.jpg"
    },
    {
        "id": 61,
        "name": "Haipham_DTKK",
        "avatar": "https://pbs.twimg.com/profile_images/1667004369295003650/q-GsxYwE_400x400.jpg"
    },
    {
        "id": 62,
        "name": "khanhsson",
        "avatar": "https://pbs.twimg.com/profile_images/1509903319803736070/85bnjLOx_400x400.jpg"
    },
    {
        "id": 63,
        "name": "vivian_muoi",
        "avatar": "https://pbs.twimg.com/profile_images/1674084054533132288/_ofzJQXu_400x400.jpg"
    },
    {
        "id": 64,
        "name": "SunnyKols",
        "avatar": "https://pbs.twimg.com/profile_images/1677270456813129730/ZCRhYwae_400x400.jpg"
    },
    {
        "id": 65,
        "name": "kenfintech",
        "avatar": "https://pbs.twimg.com/profile_images/1657059566985826305/QeCAzCNE_400x400.jpg"
    },
    {
        "id": 66,
        "name": "NbLisa_kols",
        "avatar": "https://pbs.twimg.com/profile_images/1684902225460826112/8-34pFjp_400x400.jpg"
    }
]

export {
    kols,
    kolsManager
}
