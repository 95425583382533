import LandingPage from 'pages/LandingPage'
import { Route, Routes } from 'react-router-dom'
import './App.css'

function App() {
  return (
    <main className='App'>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='*' element={<p>404</p>} />
      </Routes>
    </main>
  )
}

export default App
